<template>
  <div class="association-card" :class="{ selected: selected }">
    <div class="icon">
      <i :class="{ 'far fa-circle': !selected, 'fas fa-check': selected }"></i>
    </div>
    <div class="association">
      <div class="association-name">{{ associationName }}</div>
      <div class="parent-association-name">{{ parentAssociationName }}</div>
    </div>
    <div class="city">
      {{ associationCity }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      required: true,
      default: false
    },
    associationName: {
      type: String,
      required: true
    },
    parentAssociationName: {
      type: String,
      required: true
    },
    associationCity: {
      type: String,
      required: true
    },
    city: {
      type: String
    },
    associationId: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/_variables";

.association-card {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: $border-radius;
  border: solid 2px $input-border-color;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  @include transition();
  margin-bottom: 15px;

  .association {
    @include transition();
    padding: 0 20px;
  }

  .association-name {
    font-size: 18px;
    font-weight: $font-weight-bold;
    line-height: 22px;
  }

  .parent-association-name {
    opacity: 0.7;
  }

  .icon {
    @include transition();
    font-size: 22px;
    color: $input-border-color;
  }

  .city {
    margin-left: auto;
    background: rgba(0, 0, 0, 0.1);
    font-size: 12px;
    padding: 8px 18px;
    border-radius: 4px;
    text-transform: uppercase;
    @include transition();
  }

  &.selected {
    color: #fff;
    background: $gold;
    border-color: darken($gold, 10%);

    .city {
      background: rgba(0, 0, 0, 0.2);
    }

    .icon {
      color: #fff;
    }

    &:hover,
    &:active,
    &:focus {
      border-color: darken($gold, 20%);
      background: darken($gold, 10%);
      .icon {
        color: #fff;
      }
    }
  }

  &:hover,
  &:active,
  &:focus {
    background: #f7f7f7;
    border-color: darken($input-border-color, 10%);
    .icon {
      color: darken($input-border-color, 10%);
    }
  }
}
</style>
