<template>
  <app-card-wizard title="Birlik Belirleme">
    <template v-slot:description v-if="currentView === 0">
      <p>
        Üye olabileceğiniz birliği belirleyebilmemiz için firmanız ve ihracatını
        yaptığınız ürün/hizmet hakkında biraz bilgiye ihtiyacımız olacak.
      </p>
    </template>
    <template v-if="currentView === 0">
      <app-form-group
        :validator="$v"
        name="exportType"
        label="İhracat Türü"
        help="Firmanızın yaptığı ihracat türü"
      >
        <p-dropdown
          style="width:100%;"
          v-model="exportType"
          :options="exportTypes"
          :showClear="true"
          dataKey="name"
          scrollHeight="270px"
          optionLabel="text"
          optionValue="name"
          placeholder="Firmanızın yaptığı ihracatın türünü belirtin"
          @change="updateStore($event, 'exportType', $event.value)"
        />
      </app-form-group>

      <template v-if="exportType">
        <template v-if="isService">
          <app-form-group
            :validator="$v"
            name="sector"
            label="Sektör"
            help="Hizmet alt sektörü belirtin"
          >
            <p-dropdown
              id="sector"
              style="width:100%;"
              v-model="sector"
              :options="serviceSectors"
              :showClear="true"
              dataKey="name"
              scrollHeight="270px"
              optionLabel="text"
              optionValue="text"
              placeholder="Alt sektör seçin"
              @change="updateStore($event, 'sector', $event.value)"
            />
          </app-form-group>
          <app-form-group
            :validator="$v"
            name="hqCity"
            label="Kanuni Merkez Şehri"
            help="Firmanın kanuni merkezinin bulunduğu şehiri seçin"
          >
            <p-dropdown
              id="hqCity"
              style="width:100%;"
              v-model="hqCity"
              :options="cities"
              :showClear="true"
              :filter="true"
              filterPlaceholder="Arama yapın"
              filterLocale="tr"
              emptyFilterMessage="Sonuç bulunamadı"
              dataKey="slug"
              scrollHeight="270px"
              optionLabel="name"
              optionValue="slug"
              placeholder="Firmanın kanuni merkezinin bulunduğu şehiri seçin"
              @change="updateStore($event, 'hqCity', $event.value)"
            />
          </app-form-group>
        </template>
        <template v-else>
          <section class="form-section mt-5">
            <h4>İhraç Edilecek Ürün</h4>
            <app-form-group
              :validator="$v"
              name="hsCode"
              label="İhracatı yapılacak malın GTIP kodu"
            >
              <p-auto-complete
                id="hsCode"
                v-model="hsCode"
                :minLength="3"
                :suggestions="filteredHsCodes"
                :field="formatHsCode"
                style="width:100%"
                placeholder="GTİP kodu ya da ürün/eşya adı yazarak arayın"
                @complete="searchHsCode($event)"
              >
                <template #item="slotProps" class="auto-complete-item">
                  <div class="sub-title">{{ slotProps.item.gtip }}</div>
                  <div class="title">{{ slotProps.item.gtipDesc }}</div>
                  <div class="description">
                    {{ slotProps.item.gtip6desc }}
                  </div>
                </template>
              </p-auto-complete>
            </app-form-group>
          </section>
          <section class="form-section">
            <h4>Kanuni Merkez</h4>
            <app-form-group
              :validator="$v"
              name="hqCity"
              label="Firmanın kanuni merkezinin bulunduğu şehir"
            >
              <p-dropdown
                id="hqCity"
                style="width:100%;"
                v-model="hqCity"
                :options="cities"
                :showClear="true"
                :filter="true"
                filterPlaceholder="Arama yapın"
                filterLocale="tr"
                emptyFilterMessage="Sonuç bulunamadı"
                dataKey="slug"
                scrollHeight="270px"
                optionLabel="name"
                optionValue="slug"
                placeholder="Firmanın kanuni merkezinin bulunduğu şehiri seçin"
                @change="updateStore($event, 'hqCity', $event.value)"
              />
            </app-form-group>
          </section>
          <section class="form-section">
            <h4>Şubeler</h4>
            <p>
              Eğer firmanızın yukarıda belirttiğiniz yasal merkez dışında
              şubeleri de bulunuyorsa tüm şubelerinizin bulunduğu şehirleri
              seçin
            </p>

            <app-form-group
              :validator="$v"
              name="branchOffices"
              label="Şubeleriniz"
            >
              <p-multi-select
                id="branchOffices"
                v-model="branchOffices"
                :options="branchOfficeCities"
                display="chip"
                :filter="true"
                filterPlaceholder="Arama yapın"
                filterLocale="tr"
                dataKey="slug"
                emptyFilterMessage="Sonuç bulunamadı"
                optionLabel="name"
                optionValue="slug"
                placeholder="Şubelerinizin bulunduğu şehirleri seçin"
                style="width:100%"
                @change="onBranchOfficesChange"
                @show="onBranchOfficesShow"
                ref="branchOffices"
              ></p-multi-select>
            </app-form-group>
          </section>
        </template>
      </template>
      <div>
        <app-wizard-buttons
          :next-button-disabled="isLoading"
          :next-button-is-loading="isLoading"
          next-button-text="Uygun Birliği Bul"
          @next-button-click="onSelectExportType"
          :prev-button-visible="true"
          :prev-button-disabled="isLoading"
          @prev-button-click="
            redirect('/check-membership/' + applicationData.stateId)
          "
        ></app-wizard-buttons>
      </div>
    </template>
    <template v-if="currentView === 1">
      <div
        style="font-size:18px; font-weight:bold; margin-top:30px; color:#000;"
      >
        Üye Olmak İstediğiniz Birliği Seçin
      </div>
      <p v-if="exportType === 'hizmet'">
        Hizmet sektöründe ihracat yapıyor olduğunuzu belirttiniz. Hizmet
        sektöründeki ihraatçılar sadece Hizmet İhracatçıları Birliğine üye
        olabilmektedir. Hizmet İhracatçıları Birliği'ne üyelik başvurusu yapmak
        için seçim yapıl "Devam Et" butonuna basabilirsiniz.
      </p>
      <p v-if="exportType === 'mal'">
        Verdiğiniz bilgiler doğrultusunda ihracatını yapacağınızı belirttiğiniz
        <strong style="color:#000;">{{ hsCode.gtip }}</strong> GTIP numaralı
        <strong style="color:#000;">{{ hsCode.gtip6desc }}</strong> ürüne bağlı
        olarak firmanızın ve varsa şubelerinizin bulunduğu şehir(ler) için üye
        olabileceğiniz birlik(ler) aşağıda listelenmiştir. Başvuruya devam etmek
        için lütfen üye olmak istediğiniz birliği seçin.
      </p>
      <app-association-list
        :hqCity="getCity(hqCity)"
        :branchOffices="branchOfficeCitiesForList"
        :associations="validAssociations"
        :value="selectedItem"
        :hideLabels="exportType === 'hizmet'"
        @selected="onAssociationSelected"
      ></app-association-list>
      <div>
        <app-wizard-buttons
          :next-button-disabled="isLoading || !selectedItem"
          :next-button-is-loading="isLoading"
          next-button-text="Devam Et"
          @next-button-click="onSaveSelection"
          :prev-button-visible="true"
          :prev-button-disabled="isLoading"
          @prev-button-click="goBack"
        ></app-wizard-buttons>
      </div>
    </template>
  </app-card-wizard>
</template>

<script>
import { mapActions } from "vuex";
import { settings, rules, slugify, getEnvironment } from "../shared";
import service from "../services";
import PMultiSelect from "primevue/multiselect";
import AppAssociationList from "../components/AssociationList.vue";
import axios from "axios";

const restrictedAssociations =
  // getEnvironment() == "production" ? [101, 102, 103, 104,1105,1106,1107,1108,1115,1118,1125, 1209, 1210, 1211, 1212, 1223, 1224] : [];
  getEnvironment() == "production"
    ? [
        101,
        102,
        103,
        104,
        505,
        1105,
        1106,
        1107,
        1108,
        1115,
        1118,
        1125,
        1209,
        1210,
        1211,
        1212,
        1223,
        1224,
        1428
      ]
    : [];

//const restrictedAssociationNames = "BAİB, İTKİB, İMMİB ve İİB";
const restrictedAssociationNames = "BAİB, İTKİB, İMMİB, İİB ve HİB";

const ascSettings = { allowedAssociations: [], allowedGsNames: "" };

export default {
  components: {
    PMultiSelect,
    AppAssociationList
  },
  data() {
    return {
      currentView: 0,
      exportType: null,
      sector: null,
      hsCode: null,
      hqCity: null,
      branchOffices: [],
      exportTypes: settings.exportTypes,
      serviceSectors: settings.serviceSectors,
      cities: settings.cities,
      filteredHsCodes: null,
      isLoading: false,
      validAssociations: null,
      selectedItem: null,
      isBoVisible: false
    };
  },
  validations: {
    exportType: rules.required,
    sector: rules.required,
    hsCode: rules.hsCode,
    hqCity: rules.required
  },
  methods: {
    goBack() {
      this.currentView = 0;
    },
    getFilterInput(id) {
      return document.querySelector("#" + id + " input.p-multiselect-filter");
    },
    onBranchOfficesShow() {
      this.getFilterInput("branchOffices").focus();
    },
    onBranchOfficesChange() {
      this.$refs.branchOffices.filterValue = null;
      this.$refs.branchOffices.hide();

      this.updateApplicationData({
        branchOfficeCities: this.branchOffices.sort()
      });
    },
    onSelectExportType() {
      this.$v.exportType.$touch();
      this.$v.hqCity.$touch();

      let isValid = !this.$v.exportType.$invalid && !this.$v.hqCity.$invalid;

      if (!isValid) {
        this.scrollToError();
        return;
      }

      switch (this.exportType) {
        case "mal":
          this.$v.hsCode.$touch();

          isValid = isValid && !this.$v.hsCode.$invalid;
          break;
        case "hizmet":
          this.$v.sector.$touch();

          this.hsCode = "000000000000";

          isValid = isValid && !this.$v.sector.$invalid;

          break;
        default:
          isValid = false;
          break;
      }

      if (!isValid) {
        this.scrollToError();
        return;
      }

      this.isLoading = true;

      setTimeout(() => {
        this.findAssociationToRegister({
          hsCode: this.exportType === "mal" ? this.hsCode.gtip : "000000000000",
          hqCity: this.hqCity,
          branchOfficeCities: this.branchOffices
        })
          .then(response => {
            if (response && response.data) {
              this.validAssociations = response.data;

              const currentStep = this.applicationData.currentStep;
              currentStep.view = 1;

              this.updateApplicationData({
                exportType: this.exportType,
                hqCity: this.hqCity,
                branchOfficeCities: this.branchOffices || [],
                sector: this.sector,
                hsCode: this.hsCode ? this.hsCode.gtip : null,
                validAssociations: this.validAssociations,
                currentStep
              });

              this.currentView = 1;
            } else {
              throw new Error("Sonuç bulunamadı");
            }
          })
          .catch(() => {
            this.$swal({
              icon: "error",
              title: "Sorgulanama Yapılamadı",
              html:
                "Sorgulama yapılırken beklenmedik bir hata oluştu. Lütfen daha sonra tekrar deneyin",
              confirmButtonText: "Tamam"
            });
          })
          .then(() => {
            this.isLoading = false;
          });
      }, 500);
    },
    updateSelectedAssociation() {
      this.updateApplicationData({
        selectedAssociation: {
          ...this.selectedItem.association,
          appliedFromCity: this.selectedItem.city.slug,
          isAppliedFromBranch: this.selectedItem.branch
        }
      });
    },
    saveStateAndGoToNextStep() {
      this.updateSelectedAssociation();

      this.markStepAsCompleted(this.stepName);

      this.goTo("/applicant/" + this.applicationData.stateId);
    },
    onSaveSelection() {
      if (
        ascSettings.allowedAssociations.length > 0 &&
        !ascSettings.allowedAssociations.includes(
          this.selectedItem.association.sirkod
        )
      ) {
        const name = this.selectedItem.association.associationName;
        const phone = this.selectedItem.association.phone;

        this.$swal({
          icon: "warning",
          title: "Bu birliğe üye başvurusu alınamıyor?",
          html:
            "<b>" +
            name +
            "</b>ne henüz online üyelik başvurusu alınmamaktadır. " +
            "Sadece <b>" +
            ascSettings.allowedGsNames +
            "</b> ve  bünyesindeki birliklerden birisine üyelik başvurusunda bulunabilirsiniz. <br/><br/>" +
            "Bu birliğe üye olmak için <b>" +
            phone +
            "</b> numaralı telefondan üyelik şubesi ile görüşebilirsiniz.",
          confirmButtonText: "Tamam"
        });

        return;
      }

      if (
        this.applicationData.memberships.some(
          x => x.id === this.selectedItem.association.id && x.isActive
        )
      ) {
        this.$swal({
          icon: "warning",
          title: "Bu birliğe zaten üyesiniz?",
          html:
            "Seçtiğiniz birliğe zaten üyeliğiniz bulunmaktadır. Üyesi olduğunuz bir birliğe ikinci bir üyelik başvurusunda bulunamazsınız.",
          confirmButtonText: "Tamam"
        });

        return;
      }

      if (this.selectedItem.branch) {
        this.$swal({
          icon: "question",
          title: "Evraklarınızın var mı?",
          html:
            "Üyelik başvurusu yapmak için seçtiğiniz birliğe başvurunuzu şube adresiniz üzerinden yapıyorsunuz. Şube adresi üzerinden yapılan başvurularda talep edilecek olan evraklara ek olarak şube açılışını gösteren <b>Ticaret Sicil Gazetesi</b> de talep edilecektir. Bu nedenle başvuru yapmadan önce bu evraka sahip olduğunuzdan emin olun.",
          confirmButtonText: "Evraklarım Var",
          showCancelButton: true,
          cancelButtonText: "Hayır Yok"
        }).then(result => {
          if (result.isConfirmed) {
            this.saveStateAndGoToNextStep();
          } else {
            return;
          }
        });
      } else {
        this.saveStateAndGoToNextStep();
      }
    },
    onAssociationSelected(value) {
      this.selectedItem = value;
      this.updateSelectedAssociation();
    },
    searchHsCode(event) {
      setTimeout(() => {
        service
          .searchHsCodes(event.query)
          .then(response => {
            this.filteredHsCodes = response;
          })
          .catch(() => {
            this.filteredHsCodes = [];
          });
      }, 100);
    },
    formatHsCode(val) {
      if (!val || !val.gtip) return null;

      return val.gtip + " - " + val.gtip6desc;
    },
    getCity(val) {
      return settings.getCity(val);
    },
    ...mapActions(["findAssociationToRegister"])
  },
  watch: {
    hqCity(val) {
      if (this.branchOffices && this.branchOffices.length > 0) {
        this.branchOffices = this.branchOffices.filter(x => x != val).sort();
      }

      if (
        this.applicationData.hqAddress &&
        slugify(this.applicationData.hqAddress.city + "") != val
      ) {
        const hqAddress = this.applicationData.hqAddress;

        hqAddress.city = null;
        hqAddress.county = null;

        this.updateApplicationData({
          hqAddress
        });
      }
    },
    hsCode(val) {
      if (val && val.gtip) {
        this.updateApplicationData({ hsCode: val.gtip });
      }
    },
    exportType() {
      this.sector = null;
      this.hsCode = null;
      //this.hqCity = null;
      this.branchOffices = [];
      this.selectedItem = null;
    }
  },
  computed: {
    stepName() {
      return "choose-association";
    },
    isService() {
      return this.exportType === "hizmet";
    },
    isComodity() {
      return this.exportType === "mal";
    },
    branchOfficeCities() {
      return this.cities.filter(c => c.slug != this.hqCity);
    },
    branchOfficeCitiesForList() {
      return (this.branchOffices || []).sort().map(x => settings.getCity(x));
    }
  },
  beforeMount() {
    const appData = this.applicationData || {};
    this.exportType = appData.exportType;
    this.hqCity = appData.hqCity;
    this.branchOffices = appData.branchOfficeCities || [];
    this.sector = appData.sector;
    this.validAssociations = appData.validAssociations || {};
    this.currentView = appData.currentStep.view || 0;

    service
      .searchHsCodes(appData.hsCode)
      .then(response => {
        if (response && response.length > 0) {
          this.hsCode = response[0];
        } else {
          this.hsCode = null;
        }
      })
      .catch(() => {
        this.hsCode = null;
      });

    axios.get("/settings.json").then(r => {
      ascSettings.allowedAssociations =
        r?.data?.allowedAssociations || restrictedAssociations;
      ascSettings.allowedGsNames =
        r?.data?.allowedGsNames || restrictedAssociationNames;
    });

    this.markStepAsInCompleted(this.stepName);
  }
};
</script>

<style></style>
