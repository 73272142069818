<template>
  <div class="association-list">
    <section class="list-item" v-if="hasHqAssociations">
      <h4 v-if="!hideLabels">Kanuni Merkez / {{ hqCity.name }}</h4>
      <template v-for="association in hqAssociations" :key="association.id">
        <app-association
          :selected="isSelected(association.id, hqCity.id)"
          :associationName="association.associationName"
          :parentAssociationName="association.parentAssociationName"
          :city="hqCity.id"
          :associationCity="association.city"
          :associationId="association.id"
          @click="selectAssociation(association.id, hqCity.id, false)"
        />
      </template>
    </section>
    <template v-if="hasBranchOfficeAssociations">
      <section
        class="list-item"
        v-for="(city, index) in branchOffices"
        :key="city.id"
      >
        <h4 v-if="!hideLabels">Şube {{ index + 1 }} / {{ city.name }}</h4>
        <template
          v-for="association in getAssociationsForCity(city.id)"
          :key="association.id"
        >
          <app-association
            :selected="isSelected(association.id, city.id)"
            :associationName="association.associationName"
            :parentAssociationName="association.parentAssociationName"
            :city="city.id"
            :associationCity="association.city"
            :associationId="association.id"
            @click="selectAssociation(association.id, city.id, true)"
          />
        </template>
      </section>
    </template>
  </div>
</template>

<script>
import AppAssociation from "../components/Association.vue";
import { settings } from "../shared";

export default {
  components: {
    AppAssociation
  },
  emits: ["selected"],
  data() {
    return {
      selectedItem: null
    };
  },
  props: {
    hqCity: {
      type: Object,
      required: true
    },
    branchOffices: Array,
    associations: Object,
    value: Object,
    hideLabels: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasBranchOfficeAssociations() {
      if (!this.branchOffices || this.branchOffices.length == 0) return false;

      for (let i = 0; i < this.branchOffices.length; i++) {
        const city = this.branchOffices[i];

        const assc = this.getAssociationsForCity(city.id);

        if (assc) return true;
      }

      return false;
    },
    hasHqAssociations() {
      return this.hqAssociations != null && this.hqAssociations.length > 0;
    },
    hqAssociations() {
      if (!this.hqCity || !this.hqCity.id) return false;

      return this.getAssociationsForCity(this.hqCity.id);
    }
  },
  methods: {
    isSelected(id, city) {
      if (!this.selectedItem && this.value) {
        this.selectedItem = this.value;
      }

      return (
        this.selectedItem &&
        this.selectedItem.association.id === id &&
        this.selectedItem.city.id === city
      );
    },
    getAssociationsForCity(cityId) {
      if (!this.associations) return null;

      return this.associations[cityId];
    },
    getAssociation(id, city) {
      return this.getAssociationsForCity(city).find((x) => x.id === id);
    },
    getCity(val) {
      return settings.getCity(val);
    },
    selectAssociation(id, city, isFromBranch) {
      const selectedAssocition = this.getAssociation(id, city);

      this.selectedItem = {
        association: selectedAssocition,
        city: this.getCity(city),
        branch: isFromBranch
      };
    }
  },
  watch: {
    selectedItem() {
      this.$emit("selected", this.selectedItem);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/_variables";

.association-list {
  .list-item {
    h4 {
      font-size: 18px;
      font-weight: $font-weight-bold;
      color: $turquoise;
      margin-bottom: 15px;
    }

    margin-bottom: 45px;
  }
}
</style>
